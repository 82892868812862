const dataFaq = [
    {
        id: 1,
        title: 'How many collections does Zalez have?',
        content: 'We have 3 main collections Namely: Zalez OG (ALE), Zalez AI, and Zalez EVO each collection has a different utility.',
        show: 'show'
    },
    {
        id: 2,
        title: 'What is the difference between the three collections?',
        content: 'There are many you can check on our Whitepapper, or can join our discord server',
    },
    {
        id: 3,
        title: 'What is RAYZ?',
        content: 'RAYZ is a utility token that you can get from staking Zalez, ZalezAI, or Zalez EVO.',
    },
    {
        id: 4,
        title: 'What can RAYZ be used for?',
        content: 'for now RAYZ can be used for : Purchase NFTs in our marketplace, and join the NFT Raffle, or Rent NFTs (to try the utility of the NFT)',
    },
    {
        id: 5,
        title: 'Can I join Raid2earn with 1 Zalez?',
        content: 'Of course, you can join Raid2earn with only 1 zalez, where you can get RAYZ/BONK/ SOL',
    },
    {
        id: 6,
        title: 'Will this project be in Solana only?',
        content: 'Thats right we will stay there!',
    },
]

export default dataFaq;