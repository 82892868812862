const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/home',

    },
    {
        id: 2,
        name: 'About',
        links: '#',
        namesub: [
           
            {
                id: 1,
                sub: 'About',
                links: '/about'
            },
            {
                id: 2,
                sub: 'Road Map',
                links: '/road-map'
            },
            {
                id: 3,
                sub: 'FAQ',
                links: '/faq'
            },

        ]
    },
    {
        id: 3,
        name: 'Web 3 Service',
        links: 'https://service.zalezlabs.xyz/',

    },
    {
        id: 4,
        name: 'Buy our Collection',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Zalez OG',
                links: 'https://magiceden.io/marketplace/zalez_ale'
            },
            {
                id: 2,
                sub: 'Zalez EVO',
                links: 'https://magiceden.io/marketplace/zalez_evo'
            },
            {
                id: 3,
                sub: 'Zalez AI',
                links: 'https://magiceden.io/marketplace/zalezai'
            },
            {
                id: 4,
                sub: 'SURFBOARD',
                links: 'https://magiceden.io/marketplace/surfboard'
            },
        ]
    },
    {
        id: 5,
        name: 'Our Platform',
        links: '#',
        namesub: [
           /* {
                id: 1,
                sub: 'RAYZ Marketplace',
                links: 'https://solhq-rayz.vercel.app/'
            },*/
            {
                id: 2,
                sub: 'Staking Site',
                links: 'http://stake.zalezlabs.xyz/'
            },
            {
                id: 3,
                sub: 'Raffle House',
                links: 'https://raffle.zalezlabs.xyz/'
            },
            {
                id: 4,
                sub: 'n0t a uTiLity',
                links: 'https://notautility.zalezlabs.xyz/'
            },
            {
                id: 5,
                sub: 'Rent Marketplace',
                links: 'https://rent.cardinal.so/zalez#browse'
            },
            {
                id: 6,
                sub: 'ZALEZ RAID HUB',
                links: 'https://zalez.tombraid.app/'
            },
        ]
    },
    
]

export default menus;