const dataRoadmap = [
    {
        id: 1,
        time: 'Q1 2023',
        list: [
            {
                text: 'First Payout from Web3 Service'
            },
            {
                text: 'Mint Phase 2 Zalez EVO'
            },
            {
                text: 'Raid2Earn Platform'
            },

        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q2 2023',
        list: [
            {
                text: 'Rent Marketplace for RAYZ'
            },
            {
                text: 'Launch SolHQ (Marketplace NFT with SPL Token)'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q3 2023',
        list: [
            {
                text:'Launches Self-Service Staking Platform'
            },
            {
                text: 'Launch Marketplace for Digital Products with SOL and RAYZ'
            },
          
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q4 2023',
        list: [
            {
                text: 'Launch Hosting Box Raffle Platform'
            },
            {
                text: 'Launch our Merchandise'
            },
        ],
        positon: 'right'
    },
 
]

export default dataRoadmap;