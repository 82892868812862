import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom'

import img1 from '../../assets/images/background/bg-ft.png'
import img2 from '../../assets/images/background/bg-ft2.png'


function Footer(props) {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer id="footer">
            <div className="footer-main">
                <img src={img1} alt="" className="bg1" />
                <img src={img2} alt="" className="bg2" />
                <div className="container">
                    <ul className="widget-social">
                       
                    </ul>
                    
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="wrap-fx">
                        <div className="Copyright">
                            <p>Zalez 2023- ALL rights reserved</p>
                        </div>
                        <ul className="list">
                            <li>
                                <Link to="https://discord.com/invite/FWEsuQ4Zu6t">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;