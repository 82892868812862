const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Staking & Raffle',
        text : 'The first day after Zalez OG Mint Out with Free mint, we immediately Launch Staking and earn RAYZ, and consistently carry out NFT Raffle with Value above 1 SOL every week.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Airdrop Zalez AI',
        text : 'For those of you who have followed us from the beginning, you must know that we started from Free Mint, and we still reward holders by giving Zalez AI as an airdrop, and the utility of Zalez AI is to get NFT Airdrops from collabs and Earn RAYZ more than Zalez OG.'
    },
    {
        id: 3,
        numb: '03',
        title: "Marketplace NFT with RAYZ",
        text : 'NFT Marketplace using RAYZ token You can buy NFTs using RAYZ tokens and you can also sell NFTs and get RAYZ tokens.'
    },
    {
        id: 4,
        numb: '04',
        title: "Airdrop Collabs",
        text : 'One of the utilities of Zalez AI is getting Airdrops from Collabs, some of the NFTs that we have Airdropped are Zalez AI x Kronik, Mister G AI, Bet Bunny.'
    },
    {
        id: 5,
        numb: '05',
        title: "Partnership",
        text : 'We also have partnerships with Hub3, Buff Bunny, Seven Deadly Sins, Eta Labs, Crowdsurfer, Unirexcity, Friendly Fox Empire, and Egoverse.'
    },
    {
        id: 6,
        numb: '06',
        title: "n0t A uTiLity",
        text : 'This is one of the platforms we created to make it easier for the Solana Ecosystem, to burn NFTs, burn SPL tokens, close token accounts, create SPL tokens, and change metadata.        '
    },
]
export default dataWork;