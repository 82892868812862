import img1 from '../images/common/team6.png';
import img2 from '../images/common/Blackpanther.png';
import img4 from '../images/common/team9.png';
import img5 from '../images/common/team10.png';
import img6 from '../images/common/team11.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Zean',
        position: 'Founder,Devs, also Raid Leader',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/Irfzean'
            },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Grissal',
        position: 'Core Team',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/Baenards'
            },
        ]
    },

 

]

export default dataTeam;