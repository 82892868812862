import bgImg1 from '../images/common/_Zalez_Gif.gif';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/_Zalez_Gif.gif';

const dataSlider = [
    {
        id: 1,
        title: 'Zalez  ',
        desc : 'We are surfers on the internet, a brand built for the Solana community..',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 2,
        title: 'Zalez  ',
        desc : 'We are surfers on the internet, a brand built for the Solana community..',
        bgImg: bgImg1,
        img : img1
    },
 
    {
        id: 4,
        title: 'Zalez  ',
        desc : 'We are surfers on the internet, a brand built for the Solana community..',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 5,
        title: 'Zalez  ',
        desc : 'We are surfers on the internet, a brand built for the Solana community..',
        bgImg: bgImg1,
        img : img1
    },

]

export default dataSlider;