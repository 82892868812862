const dataAbout = [
    {
        id: 1,
        title: 'In the early stages',
        desc: 'the community will be guided by Zalez Ale. Ale Beachers are elegant and friendly guides here to help everyone explore the potential of community building on Zalez island. Although an island paradise seems like the easiest place to find happiness, nature can sometimes be dark and dangerous. This is why Ale Beachers work tirelessly to bring Peace and Wellness (RAYZ) to the island and will always be here to support. With continued work and as the community grows, Ale Beachers will bring all communities together to prosper with the nature of the island.  As more unique characters join us on the island some of the Zalez Ale will depart to provide sufficient space to all new members and spread more RAYZ, but they will leave it up to their experienced family to keep everyone on Zalez Island living peacefully.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Vision',
        desc: 'Our vision for the future is to create a strong Web3 community in this Solana space. Not only do we want to build a strong community internally, but also provide resources to other communities to assist with their development. Our plan to do this is to offer various services within Zalez Island to outside communities. All of this will be done while also providing a multitude of benefits to the habitants of Zalez Island.',
    },
    {
        id: 3,
        title: 'What is Zalez Labs?',
        desc: 'ZalezLabs is a services provider run by team with knowledge, experience, and enthusiasm in the web3 ecosystem.',
    },
]

export default dataAbout;